body, html, #root {
    height: 100%;
    /* background-image: url('bg2.jpg'); */
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
}

a {
    color: inherit;
    text-decoration: inherit; /* no underline */
}
